<template>
  <content-not-view v-if="!subPermission.store" />
  <div class="add-board-style" v-else>
    <b-card :title="$t('g.boardStyles/add_board_style')">
      <!-- form -->
      <validation-observer ref="addBoardStyleForm">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="6">
              <b-form-group
                :label="$t('g.boardStyles/board_style_name')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.boardStyles/board_style_name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                      errors[0] ? $helpers.translateValidations(errors[0],$t('g.boardStyles/board_style_name')) : errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Name -->

            <!-- Media -->
            <b-col md="6">
              <b-form-group
                :label="$t('g.boardStyles/media')"
                label-for="media"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.boardStyles/media')"
                  rules="required"
                  accept="image/*"
                >
                  <b-form-file
                    id="media"
                    accept="image/*"
                    v-model="form_data.media"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.boardStyles/drop_file_here')"
                    :drop-placeholder="$t('g.boardStyles/drop_file_here')"
                  ></b-form-file>
                  <small class="text-danger">{{
                      errors[0] ? $helpers.translateValidations(errors[0],$t('g.boardStyles/media')) : errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Media -->

            <!-- Primary Color -->
            <b-col md="6">
              <b-form-group
                :label="$t('g.boardStyles/primary_color')"
                label-for="primary_color"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.boardStyles/primary_color')"
                  rules="required"
                >
                  <b-form-input
                    id="primary_color"
                    type="color"
                    v-model="form_data.primary_color"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                      errors[0] ? $helpers.translateValidations(errors[0],$t('g.boardStyles/primary_color')) : errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Primary Color -->

            <!-- Secondary Color -->
            <b-col md="6">
              <b-form-group
                :label="$t('g.boardStyles/secondary_color')"
                label-for="secondary_color"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.boardStyles/secondary_color')"
                  :rules="`required|notEqual:${form_data.primary_color}`"
                >
                  <b-form-input
                    id="secondary_color"
                    type="color"
                    v-model="form_data.secondary_color"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                      errors[0] ? $helpers.translateValidations(errors[0],$t('g.boardStyles/secondary_color')) : errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Secondary Color -->

            <!-- is_available -->
            <b-col md="4">
              <b-form-group :label="$t('g.status')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.status')"
                  rules="required"
                >
                  <b-form-checkbox
                    switch
                    v-model="form_data.is_available"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ $t("g.boardStyles/is_available") }}
                  </b-form-checkbox>
                  <small class="text-danger">{{
                      errors[0] ? $helpers.translateValidations(errors[0],$t('g.status')) : errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- is_available -->

            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
                :disabled="is_submitted"
              >
                <div
                  v-if="is_submitted"
                  class="loading_"
                  data-loading="light"
                />
                <small v-else>{{ $t("g.submit") }}</small>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import { required } from "@core/utils/validations/validations";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
extend("notEqual", {
  params: ["other"],
  validate: (value, { other }) => {
    if (value != other) {
      return true;
    }
    return "colors_mustn't_be_sames";
  },
});
export default {
  name: "AddBoardStyle",
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    contentNotView,
  },
  data() {
    return {
      is_submitted: false,
      form_data: {
        name: null,
        primary_color: null,
        secondary_color: null,
        is_available: true,
        media: null,
      },
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "board-styles");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    validationForm() {
      this.$refs.addBoardStyleForm.validate().then((success) => {
        if (success) {
          this.is_submitted = true;
          let formData = new FormData();
          formData.append("name", this.form_data.name);
          formData.append("primary_color", this.form_data.primary_color);
          formData.append("secondary_color", this.form_data.secondary_color);
          formData.append("is_available", this.form_data.is_available ? 1 : 0);
          formData.append("image", this.form_data.media);

          this.$http
            .post("/admin/board-styles", formData)
            .then((response) => {
              this.$router.push({ name: "boardStylesList" });
              this.$helpers.makeToast(
                "success",
                this.$t("g.boardStyles/added_successfully"),
                this.$t("g.boardStyles/board_style_added_successfully")
              );
            })
            .catch((error) => {
              this.$helpers.handleError(error);
            })
            .finally(() => {
              this.is_submitted = false;
            });
        }
      });
    },
  },
};
</script>
