var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.subPermission.store)?_c('content-not-view'):_c('div',{staticClass:"add-board-style"},[_c('b-card',{attrs:{"title":_vm.$t('g.boardStyles/add_board_style')}},[_c('validation-observer',{ref:"addBoardStyleForm"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.boardStyles/board_style_name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('g.boardStyles/board_style_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheNameHere')},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0],_vm.$t('g.boardStyles/board_style_name')) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.boardStyles/media'),"label-for":"media"}},[_c('validation-provider',{attrs:{"name":_vm.$t('g.boardStyles/media'),"rules":"required","accept":"image/*"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"media","accept":"image/*","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.boardStyles/drop_file_here'),"drop-placeholder":_vm.$t('g.boardStyles/drop_file_here')},model:{value:(_vm.form_data.media),callback:function ($$v) {_vm.$set(_vm.form_data, "media", $$v)},expression:"form_data.media"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0],_vm.$t('g.boardStyles/media')) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.boardStyles/primary_color'),"label-for":"primary_color"}},[_c('validation-provider',{attrs:{"name":_vm.$t('g.boardStyles/primary_color'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"primary_color","type":"color","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.primary_color),callback:function ($$v) {_vm.$set(_vm.form_data, "primary_color", $$v)},expression:"form_data.primary_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0],_vm.$t('g.boardStyles/primary_color')) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.boardStyles/secondary_color'),"label-for":"secondary_color"}},[_c('validation-provider',{attrs:{"name":_vm.$t('g.boardStyles/secondary_color'),"rules":("required|notEqual:" + (_vm.form_data.primary_color))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"secondary_color","type":"color","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.secondary_color),callback:function ($$v) {_vm.$set(_vm.form_data, "secondary_color", $$v)},expression:"form_data.secondary_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0],_vm.$t('g.boardStyles/secondary_color')) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.status')}},[_c('validation-provider',{attrs:{"name":_vm.$t('g.status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":"","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.is_available),callback:function ($$v) {_vm.$set(_vm.form_data, "is_available", $$v)},expression:"form_data.is_available"}},[_vm._v(" "+_vm._s(_vm.$t("g.boardStyles/is_available"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0],_vm.$t('g.status')) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.is_submitted},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.is_submitted)?_c('div',{staticClass:"loading_",attrs:{"data-loading":"light"}}):_c('small',[_vm._v(_vm._s(_vm.$t("g.submit")))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }